.footer_container {
	background-image: url('../../../assets/images/footer_bg.svg');
	height: min-content;
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
}

@media (min-width: 1024px) {
	.footer_container {
		height: 100%;
	}
}
