@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	font-family: 'ProxiNova', sans-serif; /* Fallback fonts */
}

.hidden_scroll_bar::-webkit-scrollbar {
	display: none;
}

.hidden_scroll_bar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* .styled_scroll_bar {
	scrollbar-width: 2px;
} */

/* width */
.styled_scroll_bar::-webkit-scrollbar {
	width: 4px;
}

/* Track */
.styled_scroll_bar::-webkit-scrollbar-track {
	background: #f1f1f146;
}

/* Handle */
.styled_scroll_bar::-webkit-scrollbar-thumb {
	background: #88888873;
}
