*,
*::before,
*::after {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

html {
	overflow-y: scroll;
}
